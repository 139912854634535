import useSWRImmutable from 'swr/immutable'
import { FetchStatus } from 'config/constants/types'
import useSWR from 'swr'
import { getLaunchpadsApi, getSummaryLaunchpadNfts, getUserLaunchpad } from './helpers'
import { SummaryLaunchpadData } from './types'

export const useLaunchpads = (collection: string, isAirdrop = false) => {
  const { data, status, mutate } = useSWRImmutable(['launchpads', collection, isAirdrop], () =>
    getLaunchpadsApi(collection, isAirdrop),
  )

  return {
    launchpads: data ?? [],
    isFetching: status === FetchStatus.Fetching,
    refresh: mutate,
  }
}

export const useUserLaunchpad = (account: string) => {
  const { data } = useSWR(account ? ['UserLaunchpad', account] : null, async () => getUserLaunchpad(account))

  return data
}

export const useSummaryLaunchpad = (): { [key: string]: SummaryLaunchpadData } => {
  const { data } = useSWR(['SummaryLaunchpad'], async () => getSummaryLaunchpadNfts(5))

  return data
}
